import {
  ActiviteElementKey,
  CompetencesWithChefIndication,
  ElementKey,
  VehiculeMinimized,
} from "@/models";
import { Http } from "@/utils/http";

export default class DataService {
  public static async typesActivite(): Promise<ActiviteElementKey[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=typesActivite`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
  public static async typesDO(): Promise<ElementKey[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=typesDO`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async lieux(): Promise<ElementKey[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=lieux`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async vehicules(): Promise<VehiculeMinimized[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=vehicules`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async materiels(): Promise<ElementKey[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=materiels`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async competences(
    typeActiviteId?: string
  ): Promise<CompetencesWithChefIndication[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=competences${
      typeActiviteId ? `&type=${typeActiviteId}` : ""
    }`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async roles(): Promise<ElementKey[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=roles`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
}
